import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Logo from "./Logo";
import Menu from "./Menu";
import LanguageSelector from "./LanguageSelector";
import MobileMenu from "./MobileMenu";

export default function Header({ pageContext }) {
  let userLang = pageContext.pagePath.substring(0, 2);
  /*In case of index  page, inital Language will be wrong for de*/
  if (!["de", "en", "sv"].includes(userLang)) userLang = "de";

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const { logo } = data.allContentfulHeader.nodes[0];

        return (
          <div className="container relative">
            <MobileMenu
              pages={data.pages.nodes}
              pageContext={pageContext}
              initLang={userLang}
            />
            <div
              className="max-w-screen-lg relative 2xl:max-w-screen-xl mx-auto 2xl:mx-0 flex flex-wrap items-end pb-12"
              id="top"
            >
              <div
                className="w-full flex py-6 justify-end "
                style={{ zIndex: 4 }}
              >
                <div className="hidden md:block mr-10">
                  <Link to={`/${userLang}/downloads`}>Downloads</Link>
                </div>
                <LanguageSelector
                  pageContext={pageContext}
                  initialLang={userLang}
                />
              </div>
              <div className="absolute transform translate-y-7 md:translate-y-0">
                <Logo logo={logo} />
              </div>

              <div className="hidden md:block ml-auto">
                <Menu
                  pages={data.pages.nodes}
                  pageContext={pageContext}
                  initLang={userLang}
                />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

const query = graphql`
  query headerQuery {
    allContentfulHeader {
      nodes {
        title
        logo {
          file {
            url
          }
        }
      }
    }
    pages: allContentfulPage(sort: { fields: order }) {
      nodes {
        titleDe
        titleEn
        titleSv
        menuSubtitleDe
        menuSubtitleEn
        menuSubtitleSv
        id
        parentPage {
          id
        }
      }
    }
  }
`;
