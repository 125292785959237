import React from "react";
import Footer from "../components/Footer";
import Header from "../components/header/Header";
import ToTop from "../components/ToTop";

export default function Layout({ children, pageContext }) {
  return (
    <div className="w-full overflow-hidden min-h-screen flex flex-col">
      <Header pageContext={pageContext} />
      <div className="w-full h-2 bg-gradient-to-br from-blue-200 to-blue-300" />
      {children}
      <ToTop />
      <Footer pageContext={pageContext} />
    </div>
  );
}
