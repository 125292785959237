import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

export default function Footer({ pageContext }) {
    //Always getting the current year, e.g.: © 2022 SOL-VE GmbH
    var currentTime = new Date();
    var currentYear = currentTime.getFullYear();
  const footerText = pageContext?.footerText;
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const { copyright } = data.allContentfulFooter.nodes[0];

        return (
          <div className="bg-blue-300 text-gray-600 mt-auto bg-opacity-50">
            <div className="container ">
              <div className="max-w-screen-lg 2xl:max-w-screen-xl mx-auto 2xl:mx-0 flex flex-wrap py-8 text-lg mt-8 justify-between">
                {copyright && (
                  <div className=" sm:w-auto  text-sm ">
                    &copy; {currentYear} SOL-VE GmbH{" "}
                  </div>
                )}
                <div className="hidden sm:contents">
                  <div className="sm:px-4  sm:w-auto sm:py-0 text-sm">
                    {footerText}
                  </div>
                </div>
                <div className=" sm:w-auto">
                  <Link to="/impressum">
                    <div className="text-sm ">Impressum</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

const query = graphql`
  query footerQuery {
    allContentfulFooter {
      nodes {
        copyright
      }
    }
  }
`;
