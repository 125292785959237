import React from "react";
import { Link } from "gatsby";
// import solveLogo from "../../images/logo.svg";

export default function Logo({ logo }) {
  return (
    <div className="max-w-80">
      {logo && (
        <Link to={"/"}>
          <img src={logo.file.url} alt="SOL-VE Logo" className="w-40 md:w-48" />
        </Link>
      )}
      {!logo && (
        <>
          <span className="text-5xl sm:text-6xl text-blue-600">SOL-VE</span>
          <span className="text-xl text-blue-400 font-light ml-auto">GmbH</span>
        </>
      )}
    </div>
  );
}
