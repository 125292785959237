

export default function makeUrl(title, language){
    let value = title
      .toLowerCase()
      .replace(/ä/g, "ae")
      .replace(/ö/g, "oe")
      .replace(/ü/g, "ue")
      .replace(/ß/g, "ss")
      .replace(/ /g, "-")
      .replace(/\./g, "")
      .replace(/,/g, "")
      .replace(/å/g, "a")
      .replace(/\(/g, "")
      .replace("\n", "")
      .replace(/["'“„]/g, "")
      .replace(/\)/g, "")
      .replace("?", "");
    return language ? `${language}/${value}` : `${value}`;
  };