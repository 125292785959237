import React, { useState } from "react";
import DE from "../../images/germany.png";
import EN from "../../images/united-kingdom.png";
import SV from "../../images/sweden.png";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Link } from "gatsby";

export default function LanguageSelector(props) {
  const languages = {
    de: { title: "Deutsch", imgsrc: DE },
    en: { title: "English", imgsrc: EN },
    sv: { title: "Svenska", imgsrc: SV },
  };

  const initialLanguage = props.initialLang;

  let [language, setLanguage] = useState(initialLanguage);

  return (
    <>
      <Menu bg="white">
        <MenuButton
          as={Button}
          leftIcon={<ChevronDownIcon />}
          _focus={{ outline: "none" }}
        >
          <div className="flex">
            <div className="w-6">
              <img src={languages[language].imgsrc} alt="flag" />
            </div>
          </div>
        </MenuButton>
        <MenuList className="shadow-lg bg-white p-2 px-3">
          {Object.keys(languages).map((lang) => (
            <MenuItem
              onClick={() => setLanguage(lang)}
              _hover={{ outline: "none" }}
              _focus={{ outline: "none" }}
              className="hover:bg-gray-100 duration-300"
            >
              <Item
                lang={lang}
                pageData={props.pageContext?.pageData}
                title={languages[lang].title}
                img={languages[lang].imgsrc}
              />
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}

const Item = (props) => (
  <Link
    to={`/${
      props.pageData
        ? props.pageData[props.lang].path
        : props.lang !== "de"
        ? props.lang
        : ""
    }`}
  >
    <div className="flex px-4 py-1">
      <div className="w-6 mr-2 ">
        <img src={props.img} alt="flag" />
      </div>
      <div>{props.title}</div>
    </div>
  </Link>
);
