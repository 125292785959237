import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "gatsby";
import makeUrl from "../../utils/makeUrl";

const styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "28px",
    height: "20px",
    right: "1.2rem",
    top: "50%",
    zIndex: "1",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#373a47",
    padding: "0em 1em",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: { outline: "none" },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};

function MobileMenu(props) {
  const parents = props.pages.filter((page) => !page.parentPage);
  return (
    <div className="w-full md:hidden" id="outer">
      <Menu right styles={styles} width={"100%"} outerContainerId={"outer"}>
        {parents.map((parent, index) => (
          <MenuItem0
            key={index}
            parent={parent}
            pages={props.pages}
            initLang={props.initLang}
          />
        ))}

        <li className="md:hidden list-none">
          <Link to={`/${props.initLang}/downloads`}>
            <div className="my-2 text-lg text-blue-100 nav-item">Downloads</div>
          </Link>
        </li>
      </Menu>
    </div>
  );
}

const MenuItem0 = (props) => {
  const links1 = props.pages.filter(
    (page) => page.parentPage?.id === props.parent.id
  );

  return (
    <li className="list-none bm-item">
      <Link to={`/${getUrl(props.parent, props.initLang)}`}>
        <div
          className={`my-2 text-lg text-blue-100 ${
            links1.length > 0 ? "" : "nav-item"
          }`}
        >
          {getPageTitle(props.parent, props.initLang)}
        </div>
      </Link>
      {links1.length > 0 && (
        <ul className="px-3 py-0.5 bg-gray-600 -left-1/2 rounded shadow-lg hover:shadow-xl duration-500">
          <MenuItems1
            parents={links1}
            pages={props.pages}
            initLang={props.initLang}
          />
        </ul>
      )}
    </li>
  );
};

const MenuItems1 = (props) => {
  return props.parents.map((page) => (
    <MenuItem1 parent={page} pages={props.pages} initLang={props.initLang} />
  ));
};

const MenuItem1 = (props) => {
  return (
    <li>
      <Link to={`/${getUrl(props.parent, props.initLang)}`}>
        <div className="mx-0.5 my-3 nav-item text-sm text-gray-100">
          {getPageTitle(props.parent, props.initLang)}
        </div>
      </Link>
    </li>
  );
};

const getUrl = (page, initLang) => {
  if (initLang === "de") return makeUrl(page.titleDe, "de");
  if (initLang === "en") return makeUrl(page.titleEn, "en");
  if (initLang === "sv") return makeUrl(page.titleSv, "sv");
  return "";
};

const getPageTitle = (page, initLang) => {
  if (initLang === "de") return page.titleDe;
  if (initLang === "en") return page.titleEn;
  if (initLang === "sv") return page.titleSv;
  return "";
};

export default MobileMenu;
