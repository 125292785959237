import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import scrollToElement from "scroll-to-element";

const ToTop = () => {
  const [isHide, setHide] = useState(false);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setHide(window.scrollY > 0);
      };
    }
  }, []);

  return (
    <div className="py-8">
      <div className="flex justify-center">
        {isHide && (
          <a href="#top" rel="to top" onClick={(e) => smoothScroll(e, "#top")}>
            <div className="opacity-25">
              <FaArrowUp />
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

function smoothScroll(e, target) {
  if (e) e.preventDefault();
  scrollToElement(target, {
    offset: -95,
    duration: 1000,
  });
}

export default ToTop;
