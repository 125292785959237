import { Link } from "gatsby";
import React from "react";
import makeUrl from "../../utils/makeUrl";
import "./Menu.css";

export default function Menu({ pages, initLang }) {
  return (
    <div>
      <ul className="flex flex-wrap text-xl">
        <MenuItems0 pages={pages} initLang={initLang} />
      </ul>
    </div>
  );
}

const MenuItems0 = (props) => {
  const parents = props.pages.filter((page) => !page.parentPage);
  return parents.map((parent) => (
    <MenuItem0 parent={parent} pages={props.pages} initLang={props.initLang} />
  ));
};

const MenuItem0 = (props) => {
  const links1 = props.pages.filter(
    (page) => page.parentPage?.id === props.parent.id
  );

  return (
    <li className="group relative">
      <Link to={`/${getUrl(props.parent, props.initLang)}`}>
        <div
          className={`my-2 ml-4 lg:ml-8 ${links1.length > 0 ? "" : "nav-item"}`}
        >
          {getPageTitle(props.parent, props.initLang)}
        </div>
      </Link>
      {links1.length > 0 && (
        <div className="absolute left-1/2 z-40">
          <ul className="p-3 relative hidden bg-white group-hover:block -left-1/2 rounded shadow-lg hover:shadow-xl duration-1000">
            <MenuItems1
              parents={links1}
              pages={props.pages}
              initLang={props.initLang}
            />
          </ul>
        </div>
      )}
    </li>
  );
};

const MenuItems1 = (props) => {
  return props.parents.map((page) => (
    <MenuItem1 parent={page} pages={props.pages} initLang={props.initLang} />
  ));
};

const MenuItem1 = (props) => {
  return (
    <li>
      <Link to={`/${getUrl(props.parent, props.initLang)}`}>
        <div className="my-4 mx-4 nav-item text-lg">
          {getPageTitle(props.parent, props.initLang)}
          <div className="text-sm text-gray-500 ">
            {getPageSubTitle(props.parent, props.initLang)}
          </div>
        </div>
      </Link>
    </li>
  );
};

const getUrl = (page, initLang) => {
  if (initLang === "de") return makeUrl(page.titleDe, "de");
  if (initLang === "en") return makeUrl(page.titleEn, "en");
  if (initLang === "sv") return makeUrl(page.titleSv, "sv");
  return "";
};

const getPageTitle = (page, initLang) => {
  if (initLang === "de") return page.titleDe;
  if (initLang === "en") return page.titleEn;
  if (initLang === "sv") return page.titleSv;
  return "";
};

const getPageSubTitle = (page, initLang) => {
  if (initLang === "de") return page.menuSubtitleDe;
  if (initLang === "en") return page.menuSubtitleEn;
  if (initLang === "sv") return page.menuSubtitleSv;
  return "";
};
